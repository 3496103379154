import React from 'react';
import StudioLayout from "../../components/studio/studioLayout"
import Year from '../../components/studio/year';

const Year2017 = () =>
{

    const imageData = [
        {
            name: "p1",
            width: 560,
        },
        {
            name: "p2",
            width: 420,
        },
        {
            name: "p3",
            width: 346,
        },
        {
            name: "p4",
            width: 420,
        },
        {
            name: "p5",
            width: 420,
        },
        {
            name: "p6",
            width: 420,
        },
        {
            name: "p7",
            width: 315,
        },
        {
            name: "p8",
            width: 317,
        },
        {
            name: "p9",
            width: 317,
        },
        {
            name: "p10",
            width: 315,
        },
        {
            name: "p11",
            width: 420,
        },
        {
            name: "p12",
            width: 598,
        },
        {
            name: "p13",
            width: 420,
        },
        {
            name: "p14",
            width: 315,
        },
        {
            name: "p15",
            width: 420,
        },
        {
            name: "p16",
            width: 420,
        },
        {
            name: "p17",
            width: 432,
        },
        {
            name: "p18",
            width: 420,
        },
        {
            name: "p19",
            width: 183,
        },
        {
            name: "p20",
            width: 583,
        },
        {
            name: "p21",
            width: 297,
        },
        {
            name: "p22",
            width: 343,
        },
        {
            name: "p23",
            width: 315,
        },
        {
            name: "p24",
            width: 420,
        },
        {
            name: "p25",
            width: 345,
        },
        {
            name: "p26",
            width: 315,
        },
        {
            name: "p27",
            width: 483,
        },
        {
            name: "p28",
            width: 560,
        },
        {
            name: "p29",
            width: 315,
        },
        {
            name: "p30",
            width: 356,
        },
        {
            name: "p31",
            width: 420,
        },
        {
            name: "p32",
            width: 297,
        },
        {
            name: "p33",
            width: 368,
        },
        {
            name: "p34",
            width: 422,
        },
        {
            name: "p35",
            width: 471,
        },
        {
            name: "p36",
            width: 422,
        },
    ]

    let url = "https://res.cloudinary.com/dmgjq19hi/video/upload/v1567268112/2017_xit9ov.mp4"
    return (
        <StudioLayout year={2017} url={url} video={true}>
            <Year year={17} imageData={imageData} />
        </StudioLayout>
    );
}

export default Year2017;